import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeLayoutTitle } from '../../../redux/actions.js';
import { Card, CardBody, Button, Label, FormGroup, Input, Form, FormFeedback, ButtonToolbar, ButtonGroup, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import EdgeToast from '#components/EdgeToast';
import BaseAPI from '#helpers/base-api';
import { useFormik } from 'formik';
import AddMoneyToCard from './components/AddMoneyToCard.js';
import AdjustFundsOnCard from './components/AdjustFundsOnCard.js';
import ChangeCardStatus from './components/ChangeCardStatus.js';
import SimulateCardAuthorization from './components/SimulateCardAuthorization.js';
import SimulateCardSettlement from './components/SimulateCardSettlement.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
const baseAPI = new BaseAPI();

const GalileoSimulator = () => {
    const location = useLocation()
    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState('{}');
    const [simulatorEnabled, setSimulatorEnabled] = useState(process.env.REACT_APP_SIMULATOR === "true")
    const [responseData, setResponseData] = useState('{}');
    const [prnNumber, setPrnNumber] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const [activeForm, setActiveForm] = useState('Add Money to Card');
    const toggleToast = () => setShowToast(!showToast);
    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        if (location.search) {
            setPrnNumber(new URLSearchParams(location.search).get('prn'))
        }
        dispatch(changeLayoutTitle('Galileo Simulator'));
    }, [dispatch]);

    const handleAuthorization = async (values) => {
        try {
            setLoading(true);
            const registerResp = await baseAPI.post('cards/simulate-authorization', values);
            setRequestData(registerResp.data.req);
            setResponseData(registerResp.data.res);
            setLoading(false);
        } catch (e) {
            showMessageToast('danger', 'Galileo Demo', 'Process is failed.');
        }
    };

    const handleSettlement = async (values) => {
        try {
            setLoading(true);
            const registerResp = await baseAPI.post('cards/simulate-settlement', values);
            setRequestData(registerResp.data.req);
            setResponseData(registerResp.data.res);
            setLoading(false);
        } catch (e) {
            showMessageToast('danger', 'Galileo Demo', 'Process is failed.');
        }
    };

    const handleDeposit = async (values) => {
        try {
            setLoading(true);
            const registerResp = await baseAPI.post('cards/simulate-payment', values);
            setRequestData(registerResp.data.req);
            setResponseData(registerResp.data.res);
            setLoading(false);
        } catch (e) {
            showMessageToast('danger', 'Galileo Demo', 'Process is failed.');
        }
    };

    const handleAdjustment = async (values) => {
        try {
            setLoading(true);
            const registerResp = await baseAPI.post('cards/simulate-adjustment', values);
            setRequestData(registerResp.data.req);
            setResponseData(registerResp.data.res);
            setLoading(false);
        } catch (e) {
            showMessageToast('danger', 'Galileo Demo', 'Process is failed.');
        }
    };

    const handleCardStatus = async (event, values) => {
        try {
            setLoading(true);
            const registerResp = await baseAPI.post('cards/simulate-card-status', values);
            setRequestData(registerResp.data.req);
            setResponseData(registerResp.data.res);
            setLoading(false);
        } catch (e) {
            showMessageToast('danger', 'Galileo Demo', 'Process failed.');
        }
    };

    // const handleGetIdentity = async (event, values) => {
    //     event.preventDefault();
    //     try {
    //         const plaidResponse = await baseAPI.post('plaid/identity/get');
    //         setResponseData('check the browser console bruh');
    //         console.log(plaidResponse);

    //         const identities = plaidResponse.data;

    //         identities.forEach((identity) => {
    //             // Log names
    //             console.log('Names:');
    //             identity.names.forEach((name) => {
    //                 console.log(name);
    //             });
    //             // setResponseData(identity.names);

    //             // Log addresses
    //             console.log('Addresses:');
    //             identity.addresses.forEach((address) => {
    //                 console.log(
    //                     address.data.street,
    //                     address.data.city,
    //                     address.data.region,
    //                     address.data.postal_code,
    //                     address.data.country
    //                 );
    //             });

    //             // Log emails
    //             console.log('Emails:');
    //             identity.emails.forEach((email) => {
    //                 console.log(email.data);
    //             });

    //             // Log phone numbers (if needed)
    //             console.log('Phone Numbers:');
    //             identity.phone_numbers.forEach((phone) => {
    //                 console.log(phone.data);
    //             });
    //         });
    //     } catch (e) {
    //         showMessageToast('danger', 'Plaid Identity', 'Could not load identity');
    //     }
    // };

    //  ****** FORM VALIDATION ******

    const depositValidationSchema = Yup.object().shape({
        amount: Yup.number()
            .moreThan(0, '*Deposit amount must be greater than 0')
            .required('*Please enter a deposit amount.'),
    });

    const depositFormik = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: depositValidationSchema,
        onSubmit: (values) => {
            handleDeposit({ ...values, accountNo: prnNumber });
        },
    });

    const adjustmentValidationSchema = Yup.object().shape({
        debitCreditIndicator: Yup.string().required('*Please enter a choose an option'),
        amount: Yup.number().moreThan(0, '*Amount must be greater than 0').required('*Please enter an amount'),
    });

    const adjustmentFormik = useFormik({
        initialValues: {
            debitCreditIndicator: '',
            amount: '',
        },
        validationSchema: adjustmentValidationSchema,
        onSubmit: (values) => {
            handleAdjustment({ ...values, accountNo: prnNumber });
        },
    });

    const cardStatusSchema = Yup.object().shape({
        active: Yup.string().required('*Please select a status'),
    });

    const cardStatusFormik = useFormik({
        initialValues: {
            active: '',
        },
        validationSchema: cardStatusSchema,
        onSubmit: (values) => {
            handleCardStatus({ ...values, accountNo: prnNumber });
        },
    });

    const authorizationSchema = Yup.object().shape({
        amount: Yup.number().moreThan(0, 'Amount must be greater than 0').required(),
        association: Yup.string().required('*An association is required'),
        merchantName: Yup.string().required('*A merchant name is required'),
        mcc: Yup.string().required(),
    });

    const authorizationFormik = useFormik({
        initialValues: {
            amount: '',
            association: 'visa',
            merchantName: 'DraftKings',
            mcc: '7995',
        },
        validationSchema: authorizationSchema,
        onSubmit: (values) => {
            handleAuthorization({ ...values, accountNo: prnNumber });
        },
    });

    const cardSettlementSchema = Yup.object().shape({
        association: Yup.string().required('*Please enter an association'),
        authId: Yup.string().required('*Please enter an Authorization ID'),
        mcc: Yup.string().required('*Please enter an MCC'),
    });

    const cardSettlementFormik = useFormik({
        initialValues: {
            association: 'visa',
            authId: '',
            mcc: '7995',
        },
        validationSchema: cardSettlementSchema,
        onSubmit: (values) => {
            handleSettlement({ ...values, accountNo: prnNumber });
        },
    });

    const handleComponentDisplay = () => {
        switch (activeForm) {
            case 'Add Money to Card':
                return (
                    <AddMoneyToCard
                        simulatorEnabled={simulatorEnabled}
                        prnNumber={prnNumber}
                        depositFormik={depositFormik}
                    />
                );
            case 'Adjust Funds on Card':
                return (
                    <AdjustFundsOnCard
                        simulatorEnabled={simulatorEnabled}
                        prnNumber={prnNumber}
                        adjustmentFormik={adjustmentFormik}
                    />
                );
            case 'Change Card Status':
                return (
                    <ChangeCardStatus
                        simulatorEnabled={simulatorEnabled}
                        prnNumber={prnNumber}
                        cardStatusFormik={cardStatusFormik}
                    />
                );
            case 'Simulate Card Authorization':
                return (
                    <SimulateCardAuthorization
                        simulatorEnabled={simulatorEnabled}
                        prnNumber={prnNumber}
                        authorizationFormik={authorizationFormik}
                    />
                );
            case 'Simulate Card Settlement':
                return (
                    <SimulateCardSettlement
                        simulatorEnabled={simulatorEnabled}
                        prnNumber={prnNumber}
                        cardSettlementFormik={cardSettlementFormik}
                    />
                );
            default:
                return;
        }
    }

    const handleActiveButton = (e) => {
        setActiveForm(e.target.innerText);
    }

    const buttonStyle = (text) => {
        return activeForm !== text;
    }

    return (
        <>
            <section className="section">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <ButtonGroup className="mt-4">
                        <Button
                            color='primary'
                            onClick={handleActiveButton}
                            outline={buttonStyle('Add Money to Card')}
                        >
                            Add Money to Card
                        </Button>
                        <Button
                            color='primary'
                            onClick={handleActiveButton}
                            outline={buttonStyle('Adjust Funds on Card')}
                        >
                            Adjust Funds on Card
                        </Button>
                        <Button
                            color='primary'
                            onClick={handleActiveButton}
                            outline={buttonStyle('Change Card Status')}
                        >
                            Change Card Status
                        </Button>
                        <Button
                            color='primary'
                            onClick={handleActiveButton}
                            outline={buttonStyle('Simulate Card Authorization')}
                        >
                            Simulate Card Authorization
                        </Button>
                        <Button
                            color='primary'
                            onClick={handleActiveButton}
                            outline={buttonStyle('Simulate Card Settlement')}
                        >
                            Simulate Card Settlement
                        </Button>
                    </ButtonGroup>
                    <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                        <FormGroup className="position-relative">
                            <Label className="h4 font-weight-bold" for="prn">Payment Reference Number</Label>
                            <Input
                                type="text"
                                id="accountNo"
                                name="accountNo"
                                value={prnNumber}
                                onChange={(e) => setPrnNumber(e.target.value)}
                                placeholder='Enter PRN Number'
                                disabled={!simulatorEnabled}
                                invalid={prnNumber === ''} />
                            {prnNumber === '' && (
                                <FormFeedback
                                    invalid={prnNumber === ''}
                                    tooltip>
                                    * PRN Number required.
                                </FormFeedback>
                            )}
                        </FormGroup>

                    </div>


                </div>

                <div className="row">
                    <div className="col-6">

                        {/* <Card className="shadow border-0 mt-4">
                            <CardBody>
                                <h4 className="header-title">Plaid</h4>
                                <Form onSubmit={handleGetIdentity}>
                                    <Button type="submit" color="dark">
                                        Get My Identity
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card> */}
                        {handleComponentDisplay()}

                    </div>
                    <div className="col-6">
                        <Card className="shadow border-0 mt-4">
                            <CardBody>
                                <h4 className="header-title">Request / Response</h4>
                                {!loading ?
                                    <>
                                        <p className="mb-2">Sent Request</p>
                                        <pre>{requestData}</pre>
                                        <p className="mt-2">Received Response</p>
                                        <pre>{responseData}</pre>
                                    </>
                                    :
                                    <>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Spinner style={{ height: '3rem', width: '3rem' }}> </Spinner>
                                        </div>
                                    </>
                                }

                            </CardBody>
                        </Card>
                    </div>
                </div>
            </section>
            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />
        </>
    );
};

export default GalileoSimulator;