import React from 'react';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";

const SimulateCardAuthorization = ({ simulatorEnabled, authorizationFormik, prnNumber }) => {
    return (
        <Card className="shadow border-0 mt-4">
            <CardBody>
                <h4 className="header-title">Create Simulated Card Authorization</h4>
                <Form onSubmit={authorizationFormik.handleSubmit}>
                    <FormGroup className="position-relative">
                        <Label for="amount">Amount</Label>
                        <Input
                            id="amount"
                            name="amount"
                            type="number"
                            placeholder="Amount"
                            onChange={authorizationFormik.handleChange}
                            onBlur={authorizationFormik.handleBlur}
                            value={authorizationFormik.values.amount}
                            invalid={
                                authorizationFormik.errors.amount && authorizationFormik.touched.amount
                            }
                        />
                        {authorizationFormik.errors.amount && authorizationFormik.touched.amount ? (
                            <FormFeedback
                                invalid={
                                    authorizationFormik.errors.amount &&
                                    authorizationFormik.touched.amount
                                }
                                tooltip>
                                {authorizationFormik.errors.amount}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label for="association">Association (visa, mc_auth)</Label>
                        <Input
                            id="associaton"
                            name="association"
                            type="text"
                            placeholder="Association"
                            className="mb-2"
                            onChange={authorizationFormik.handleChange}
                            onBlur={authorizationFormik.handleBlur}
                            value={authorizationFormik.values.association}
                            invalid={
                                authorizationFormik.errors.association &&
                                authorizationFormik.touched.association
                            }
                        />
                        {authorizationFormik.errors.association &&
                            authorizationFormik.touched.association ? (
                            <FormFeedback
                                invalid={
                                    authorizationFormik.errors.association &&
                                    authorizationFormik.touched.association
                                }
                                tooltip>
                                {authorizationFormik.errors.association}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label for="merchantName">Merchant Name</Label>
                        <Input
                            id="merchantName"
                            name="merchantName"
                            label="Merchant Name"
                            type="text"
                            placeholder="Merchant Name"
                            className="mb-2"
                            onChange={authorizationFormik.handleChange}
                            onBlur={authorizationFormik.handleBlur}
                            value={authorizationFormik.values.merchantName}
                            invalid={
                                authorizationFormik.errors.merchantName &&
                                authorizationFormik.touched.merchantName
                            }
                        />
                        {authorizationFormik.errors.merchantName &&
                            authorizationFormik.touched.merchantName ? (
                            <FormFeedback>{authorizationFormik.errors.merchantName}</FormFeedback>
                        ) : null}
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label for="mcc">MCC</Label>
                        <Input
                            id="mcc"
                            name="mcc"
                            type="text"
                            placeholder="MCC"
                            className="mb-2"
                            onChange={authorizationFormik.handleChange}
                            onBlur={authorizationFormik.handleBlur}
                            value={authorizationFormik.values.mcc}
                            invalid={authorizationFormik.errors.mcc && authorizationFormik.touched.mcc}
                        />
                        {authorizationFormik.errors.mcc && authorizationFormik.touched.mcc ? (
                            <FormFeedback
                                invalid={
                                    authorizationFormik.errors.mcc && authorizationFormik.touched.mcc
                                }
                                tooltip>
                                {authorizationFormik.errors.mcc}
                            </FormFeedback>
                        ) : null}
                    </FormGroup>
                    <Button
                        type="submit"
                        color="dark"
                        disabled={
                            authorizationFormik.errors.association ||
                            authorizationFormik.errors.amount ||
                            authorizationFormik.errors.merchantName ||
                            authorizationFormik.errors.mcc ||
                            prnNumber === '' ||
                            !simulatorEnabled
                        }>
                        Submit
                    </Button>
                </Form>
            </CardBody>
        </Card>
    )
}

export default SimulateCardAuthorization;