const UserStatus = Object.freeze({
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    WAITLIST: 'waitlist',
    STATE_PENDING: 'state_pending',
    SUSPENDED: 'suspended',
    INFUNNEL: 'infunnel',
    DELETED: 'Deleted',
    DELINQUENT: 'Delinquent',
    DEFAULT: 'Default',
    FAILED_OFAC: 'failed_ofac',
    FAILED_PEP: 'failed_pep',
    CLOSED: 'closed',
});

const SetupStatus = Object.freeze({
    EMAIL_VERIFICATION: 'verify-email',
    DEPOSIT_AGREEMENTS: 'deposit-agreements',
    LINK_BANK_ACCOUNT: 'link-bank-account',
    CUSTOMER_INFO: 'customer-info',
    CUSTOMER_SSN: 'customer-ssn',
    KYC_IN_PROGRESS: 'kyc-in-progress',
    ADD_TO_CRB: 'add-to-crb',
    ADD_TO_GALILEO: 'add-to-galileo',
    COMPLETE: 'complete',
});

// Transaction type mapping
const ActivityTypeMap = {
    AB: 'Allpoint Backout',
    AD: 'Withdrawal',
    AP: 'Allpoint Authorization',
    AS: 'Allpoint Settlement',
    AU: 'Mastercard Credit authorization',
    AX: 'Allpoint Authorization Expiration',
    BA: 'Allpoint Preauthorization Backout',
    BC: 'Discover Backout',
    BD: 'Maestro/Cirrus Backout',
    BO: 'Mastercard credit Backout',
    BP: 'Pulse Backout',
    BS: 'Star Authorization Backout',
    BV: 'Visa Authorization Backout',
    CX: 'Discover Expired Authorization',
    DA: 'Denied Authorization; Non-financial Transaction.',
    DB: 'Maestro/Cirrus Authorization',
    DC: 'Discover Authorization',
    DD: 'Denied Maestro Authorization',
    DX: 'Maestro/Cirrus Expired Authorization',
    EX: 'Mastercard Credit Expired Authorization',
    FE: 'Fee',
    IS: 'Visa Interlink Settlement',
    MP: 'Star MoneyPass Settlement',
    OB: 'Umbrella Reserve Release/Backout',
    OE: 'Umbrella Reserve Release/Expiration',
    OR: 'Umbrella Reserve',
    OT: 'Umbrella Take',
    PB: 'Mastercard Credit Preauthorization Backout',
    PE: 'Pending Payment',
    PL: 'Visa Plus Settlement',
    PM: 'Payment',
    PR: 'Star Presto Settlement',
    PS: 'Star Preauthorization Backout',
    PU: 'Pulse Authorization',
    PV: 'Visa Preauthorization Backout',
    PX: 'Pulse Expired Authorization',
    SC: 'Discover Settlement',
    SD: 'Maestro/Cirrus Settlement',
    SE: 'Mastercard Credit Settlement',
    SP: 'Pulse Settlement',
    SS: 'Star Settlement',
    ST: 'Star Authorization',
    SX: 'Star Expired Authorization',
    TE: 'Transaction Expired',
    TH: 'Transaction Hold',
    VI: 'Visa Authorization',
    VS: 'Visa Settlement',
    VX: 'Visa Expired Authorization',
};

// HubspotUser
const HubspotAccountStatus = Object.freeze({
    ACCOUNT_ACTIVE: 'Account Active',
    DORMANT: 'Dormant',
    WAITLIST: 'Waitlist',
    STATE_PENDING: 'StatePending',
    INACTIVE: 'Inactive',
    DELETED: 'Deleted',
    SUSPENDED: 'Suspended',
    DELINQUENT: 'Delinquent',
    DEFAULT: 'Default',
    CLOSED: 'Closed',
});

const SettingLimitLevelType = Object.freeze({
    HALF_NOTIFY: 'Warning Notify',
    QUARTER_NOTIFY: 'Danger Notify',
    BLOCK_NOTIFY: 'Block Boost Limit',
});

const UserRole = Object.freeze({
    ADMIN: 'admin',
    SUPPORT: 'support',
    USER: 'user',
});

const BoostSpeedType = Object.freeze({
    ACH_5_DAYS: 'ach_5_days',
    ACH_3_DAYS: 'ach_3_days',
    ACH_1_DAY: 'ach_1_day',
    ACH_INSTANT: 'ach_instant',
});

// PaymentHistory
const PaymentHistoryType = Object.freeze({
    MATCHED_DEPOSIT: 'matched_deposit',
    BOOST: 'boost',
    AUTHORIZATION: 'authorization',
    REFUND: 'refund',
    DEPOSIT: 'deposit',
});

const AdjustmentModel = Object.freeze({
    ADJUSTMENT: 'GalileoAdjustment',
    PAYMENT: 'GalileoPayment',
});

// Galileo
const GalileoAccountStatus = Object.freeze({
    CANCELED: 'C',
    DISABLED: 'D',
    FAILED_ID_VERIFICATION: 'F',
    SUSPENDED_NEVER_ACTIVATED: 'J',
    SUSPENDED: 'K',
    MOVED_PROGRAM: 'M',
    ACTIVE: 'N',
    PASSED_ID_VERIFICATION: 'P',
    DELINQUENT: 'Q',
    CHARGED_OFF: 'R',
    ID_VERIFICATION_IN_PROCESS: 'T',
    UPGRADED: 'U',
    APPLICATION_SUBMITTED: 'V',
    WAITING_TO_BE_PROCESSED: 'W',
    CANCELED_WITHOUT_REFUND: 'Z',
});

const GalileoCardStatus = Object.freeze({
    LOST_CARD_WAITING_FOR_SUFFICIENT_FUNDS: 'A',
    BLOCKED: 'B',
    CANCELED: 'C',
    DISABLED: 'D',
    LOST_CARD: 'L',
    ACTIVE: 'N',
    OPERATIONS_HOLD: 'O',
    DELINQUENT: 'Q',
    CHARGED_OFF: 'R',
    STOLEN_CARD: 'S',
    VOIDED: 'V',
    WAITING_FOR_PAYMENT: 'W',
    SET_TO_EMBOSS: 'X',
    READY_TO_ACTIVATE: 'Y',
    CANCELED_WITHOUT_REFUND: 'Z',
});

const GalileoAccountStatusText = Object.freeze({
    C: 'CANCELED',
    D: 'DISABLED',
    F: 'FAILED_ID_VERIFICATION',
    J: 'SUSPENDED_NEVER_ACTIVATED',
    K: 'SUSPENDED',
    M: 'MOVED_PROGRAM',
    N: 'ACTIVE',
    P: 'PASSED_ID_VERIFICATION',
    Q: 'DELINQUENT',
    R: 'CHARGED_OFF',
    T: 'ID_VERIFICATION_IN_PROCESS',
    U: 'UPGRADED',
    V: 'APPLICATION_SUBMITTED',
    W: 'WAITING_TO_BE_PROCESSED',
    Z: 'CANCELED_WITHOUT_REFUND',
});

const GalileoCardStatusText = Object.freeze({
    A: 'LOST_CARD_WAITING_FOR_SUFFICIENT_FUNDS',
    B: 'BLOCKED',
    C: 'CANCELED',
    D: 'DISABLED',
    L: 'LOST_CARD',
    N: 'ACTIVE',
    O: 'OPERATIONS_HOLD',
    Q: 'DELINQUENT',
    R: 'CHARGED_OFF',
    S: 'STOLEN_CARD',
    V: 'VOIDED',
    W: 'WAITING_FOR_PAYMENT',
    X: 'SET_TO_EMBOSS',
    Y: 'READY_TO_ACTIVATE',
    Z: 'CANCELED_WITHOUT_REFUND',
});

// RepaymentIntent
const RepaymentIntentStatus = Object.freeze({
    IN_SCHEDULE: 'in-schedule',
    ON_TIME: 'on-time',
    FAILED: 'failed',
    PAID: 'paid',
    PAID_EARLY: 'paid-early',
    PAID_LATE: 'paid-late',
    PENDING: 'pending',
});

const TransactionType = Object.freeze({
    MATCHED_DEPOSIT: 'matched_deposit',
    BOOST: 'boost',
    AUTHORIZATION: 'authorization',
    REFUND: 'refund',
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
    CARD_DEPOSIT: 'card_deposit',
    CREDIT: 'credit',
    DEBIT: 'debit',
});

const AchRequestStatus = Object.freeze({
    CREATED: 'Created',
    SENT: 'Sent',
    COMPLETE: 'Complete',
    RETURNED: 'Returned',
    QUEUED: 'Queued',
    RELEASED: 'Released',
    RECEIVED: 'Received',
    CANCELLED: 'Cancelled',
});

// ACH Request EdgeType
const AchEdgeType = Object.freeze({
    REPAYMENT_EARLY: 'repayment-early',
    REPAYMENT_LATE: 'repayment-late',
    REPAYMENT_AUTO: 'repayment-auto',
    REPAYMENT_MANUALLY: 'repayment-manually',
    MATCHED_DEPOSIT: 'matched-deposit',
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdraw',
});

module.exports = {
    UserStatus,
    UserRole,
    HubspotAccountStatus,
    SettingLimitLevelType,
    BoostSpeedType,
    PaymentHistoryType,
    AdjustmentModel,

    GalileoAccountStatus,
    GalileoCardStatus,
    GalileoAccountStatusText,
    GalileoCardStatusText,

    RepaymentIntentStatus,
    SetupStatus,

    TransactionType,
    AchRequestStatus,
    AchEdgeType,

    ActivityTypeMap,
};
